<script>
	import { formPromptDialog } from '$lib/stores/formPrompt';
	import { appStores } from '$lib/stores/appStores';
	import { Translator } from '$lib/js/translations/Translator';
	import { globalAlert } from '$lib/stores/globalAlert';

	const T = new Translator($appStores.language, [
		// Page translations
		{
			en: 'Please enter a valid number',
			'zh-tw': '請輸入有效號碼',
		},
	]);

	import SlotModal from '$lib/components/Modals/SlotModal.svelte';
	import Button from '$lib/components/Controls/Button.svelte';
	import TextBox from '$lib/components/Controls/TextBox.svelte';
	import Input from '$lib/components/Controls/Input.svelte';

	let open = '';
	let message = '';
	let title = '';
	let resolve;
	let mods = undefined;

	let values = [];

	/**
	 *  Elements to be included in the form
	 *
	 *  [{
	 *   key: string,
	 *   type: string,
	 *   label: string,
	 *   placeholder: string,
	 *   value: string,
	 *   required: boolean
	 *  }]
	 */

	let elements = [];

	/**
	 * Results of the form returned on resolve(results)
	 *
	 * [{
	 *  key: string,
	 *  value: string
	 * }]
	 */

	let results = [];

	// Mods:
	// - type (textbox (default) || input)
	// - maxlength
	// - confirmText
	// - confirmButtonClass
	// - cancelText
	// - cancelButtonClass
	// - iconHtml
	// - topComponentSlot
	// - topComponentSlotProps

	formPromptDialog.subscribe((val) => {
		if (val !== null) {
			open = true;
			elements = val.elements;
			message = val.message;
			title = val.title;
			mods = val.mods;
			resolve = val.resolve;

			// Load starting values
			values = elements.map((element) => element.value ?? '');
		} else {
			open = false;
			elements = [];
			message = null;
			title = null;
			mods = undefined;
			resolve = null;
			values = [];
		}
	});

	const getResults = () => {
		results = [];
		elements.forEach((element, i) => {
			results.push({
				key: element.key,
				value: values[i],
			});
		});

		return results;
	};

	const handleCancel = () => {
		resolve(undefined);
		formPromptDialog.set(null);
	};

	function handleConfirm() {
		resolve(getResults());
		formPromptDialog.set(null);
	}
</script>

<SlotModal
	modalClasses="modal modal-md"
	zIndex="999"
	{title}
	bind:show={open}
	on:outsideClick={handleCancel}
	on:closeButtonClick={handleCancel}
>
	<div class="flex w-full flex-col gap-2 py-3">
		{#if mods?.iconHtml}
			<div class="grid content-center py-4 px-4 text-center">
				{@html mods.iconHtml}
			</div>
		{/if}

		{#if mods?.topComponentSlot}
			<div class="grid content-center py-4 px-4 text-center">
				<svelte:component this={mods?.topComponentSlot} {...mods?.topComponentSlotProps ?? {}} />
			</div>
		{/if}

		{#if message?.length}
			<div class="grid content-center py-2 px-4 text-center">
				{@html message}
			</div>
		{/if}

		<div class="flex flex-col gap-2 py-1 pb-2 text-left">
			{#each elements ?? [] as element, i (element.key)}
				<div>
					{#if mods?.type === 'textbox'}
						<TextBox
							label={element.label}
							placeholder={element.placeholder}
							maxlength={mods?.maxlength}
							required={element.required ?? false}
							bind:value={values[i]}
						/>
					{:else}
						<Input
							label={element.label}
							placeholder={element.placeholder}
							maxlength={element?.maxlength}
							type={element?.inputType ?? 'text'}
							pattern={element?.pattern}
							inputmode={element?.inputmode}
							step={element?.step}
							min={element?.min}
							max={element?.max}
							required={element.required ?? false}
							bind:value={values[i]}
						/>
					{/if}
				</div>
			{/each}
		</div>

		<div class="grid w-full grid-cols-2 gap-2 pb-1">
			<div>
				<Button on:click={handleConfirm} class="w-full {mods?.confirmButtonClass ?? 'btn-md btn-blue btn-solid w-full'}">
					{mods?.confirmText ?? T.out('Submit')}
				</Button>
			</div>
			<div>
				<Button on:click={handleCancel} class="w-full {mods?.cancelButtonClass ?? 'btn-md btn-light-gray btn-text-black w-full'}">
					{mods?.cancelText ?? T.out('Cancel')}
				</Button>
			</div>
		</div>
	</div>
</SlotModal>
