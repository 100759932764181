<script>
	import { numberPromptDialog } from '$lib/stores/numberPrompt';
	import { appStores } from '$lib/stores/appStores';
	import { Translator } from '$lib/js/translations/Translator';
	import { numberValidatorAllowUndefined } from '$lib/js/validators';
	import { globalAlert } from '$lib/stores/globalAlert';

	const T = new Translator($appStores.language, [
		// Page translations
	]);

	import SlotModal from '$lib/components/Modals/SlotModal.svelte';
	import Button from '$lib/components/Controls/Button.svelte';
	import Input from '$lib/components/Controls/Input.svelte';
	import HashIcon from '$lib/components/Icons/HashIcon.svelte';

	let open = '';
	let message = '';
	let title = '';
	let label = '';
	let placeholder = '';
	let resolve;
	let value;

	let mods = undefined;

	// Mods:
	// - confirmText
	// - confirmButtonClass
	// - cancelText
	// - cancelButtonClass
	// - iconHtml
	// - topComponentSlot
	// - topComponentSlotProps

	numberPromptDialog.subscribe((val) => {
		if (val !== null) {
			open = true;
			message = val.message;
			title = val.title;
			label = val.label;
			placeholder = val.placeholder;
			mods = val.mods;
			value = val?.mods?.value;
			resolve = val.resolve;
		} else {
			open = false;
			message = null;
			title = null;
			label = null;
			placeholder = null;
			mods = undefined;
			resolve = null;
		}
	});

	const handleCancel = () => {
		resolve(undefined);
		numberPromptDialog.set(null);
	};

	function handleConfirm() {
		// Must be a valid number
		if (isNaN(value)) {
			return globalAlert.set({
				message: T.out('Please enter a valid number'),
				type: 'error',
			});
		}

		if (!value) {
			resolve(0);
		} else if (mods?.type == 'decimal') {
			resolve(parseFloat(value));
		} else {
			resolve(parseInt(value));
		}

		numberPromptDialog.set(null);
	}
</script>

<SlotModal
	modalClasses="modal modal-md"
	zIndex="999"
	{title}
	bind:show={open}
	on:outsideClick={handleCancel}
	on:closeButtonClick={handleCancel}
>
	<form on:submit|preventDefault|stopPropagation={handleConfirm} class="flex w-full flex-col gap-3 py-3">
		{#if mods?.iconHtml}
			<div class="grid content-center py-4 px-4 text-center">
				{@html mods.iconHtml}
			</div>
		{/if}
		{#if mods?.topComponentSlot}
			<div class="grid content-center py-4 px-4 text-center">
				<svelte:component this={mods?.topComponentSlot} {...mods?.topComponentSlotProps ?? {}} />
			</div>
		{/if}
		<div class="grid content-center py-2 px-4 text-center">
			{@html message}
		</div>
		<div class="grid content-center px-2 pb-2 text-center">
			{#if mods?.type == 'decimal'}
				<Input
					label={mods?.label}
					placeholder={mods?.placeholder}
					bind:value
					validator={numberValidatorAllowUndefined}
					type="number"
					pattern={'[0-9]*'}
					inputmode={'decimal'}
					step={mods?.step}
					min={mods?.min}
					max={mods?.max}
					usePretextSlot={true}
					centerText={true}
					autofocus={false}
					removePlaceholderOnFocus={true}
					autocomplete={'off'}
				>
					<HashIcon class="icon icon-tabler icon-tabler-hash h-5 w-5" />
				</Input>
			{:else}
				<Input
					label={mods?.label}
					placeholder={mods?.placeholder}
					bind:value
					validator={numberValidatorAllowUndefined}
					type="number"
					pattern={'[0-9]*'}
					inputmode={'numeric'}
					step={mods?.step}
					min={mods?.min}
					max={mods?.max}
					usePretextSlot={true}
					centerText={true}
					autofocus={false}
					removePlaceholderOnFocus={true}
					autocomplete={'off'}
				>
					<HashIcon class="icon icon-tabler icon-tabler-hash h-5 w-5" />
				</Input>
			{/if}
		</div>

		<div class="grid w-full grid-cols-2 gap-4 px-2">
			<div>
				<Button type="submit" class="w-full {mods?.confirmButtonClass ?? 'btn-md btn-blue btn-solid w-full'}">
					{mods?.confirmText ?? T.out('Submit')}
				</Button>
			</div>
			<div>
				<Button on:click={handleCancel} class="w-full {mods?.cancelButtonClass ?? 'btn-md btn-light-gray btn-text-black w-full'}">
					{mods?.cancelText ?? T.out('Cancel')}
				</Button>
			</div>
		</div>
	</form>
</SlotModal>
