<script>
	import { session, page } from '$app/stores';
	import { appStores } from '$lib/stores/appStores';
	import { globalAlert } from '$lib/stores/globalAlert';
	import { Translator } from '$lib/js/translations/Translator';
	import { tryParseBody } from '$lib/js/async_utils';

	const T = new Translator($appStores.language, [
		// Page translations
		{
			en: 'Report Issue',
			'zh-tw': '做報告',
		},
		{
			en: 'Priority',
			'zh-tw': '優先事項',
		},
		{
			en: 'Select priority',
			'zh-tw': '選擇優先級',
		},
		{
			en: 'Urgency',
			'zh-tw': '緊急程度',
		},
		{
			en: 'Select urgency',
			'zh-tw': '請選擇緊急程度',
		},
		{
			en: 'Urgent',
			'zh-tw': '緊急',
		},
		{
			en: 'Problem',
			'zh-tw': '問題',
		},
		{
			en: 'I Cannot Work',
			'zh-tw': '我不能工作',
		},
		{
			en: 'I Can Work, But It Is Annoying',
			'zh-tw': '我可以工作，但很煩人',
		},
		{
			en: 'Problem details',
			'zh-tw': '問題描述',
		},
		{
			en: 'Enter problem details',
			'zh-tw': '請輸入問題描述',
		},
		{
			en: 'Report submitted to engineering. Thank you!',
			'zh-tw': '報告提交給工程部。謝謝你！',
		},
		{
			en: 'Error occured submitting bug report, please contact engineering directly.',
			'zh-tw': '回報失敗，請直接聯繫工程師。',
		},
		{
			en: "I can't do my job",
			'zh-tw': '我無法做好我的工作',
		},
		{
			en: "I can work, but it's annoying",
			'zh-tw': '我可以工作，但很煩人',
		},
		{
			en: 'Your report will go to a queue that engineers will investigate',
			'zh-tw': '您的報告將進入隊列，工程師將進行調查',
		},
		{
			en: 'Issue',
			'zh-tw': '問題',
		},
		{
			en: 'Describe issue',
			'zh-tw': '描述問題',
		},
		{
			en: 'Loom',
			'zh-tw': 'Loom',
		},
		{
			en: 'Enter Loom Link',
			'zh-tw': '輸入 Loom 連結',
		},
		{
			en: 'Image / Video',
			'zh-tw': '圖片/影片',
		},
	]);

	import FileDropzone from '$lib/components/Misc/Files/FileDropzone.svelte';
	let getFile, clearFile, readFile, selectedFile;

	import SingleChoiceBars from '$lib/components/Controls/Choices/SingleChoiceBars.svelte';
	import SlotModal from '$lib/components/Modals/SlotModal.svelte';
	import TextBox from '$lib/components/Controls/TextBox.svelte';
	import Input from '$lib/components/Controls/Input.svelte';
	import Button from '$lib/components/Controls/Button.svelte';
	import LoadingSpinner from '$lib/components/Base/Loading/LoadingSpinner.svelte';

	export let open = false;

	let priority = undefined;
	let note = undefined;
	let loomLink = undefined;
	let loading = false;

	let titleHTML;

	$: {
		if ($appStores.language) {
			titleHTML = `
		<div class="flex flex-col gap-1">
			<div class="text-lg">⚠️ ${T.out('Report Issue')}</div>
			<div class="text-xs text-gray-500">${T.out('Your report will go to a queue that engineers will investigate')}</div>
		</div>
		`;
		}
	}

	export const openModal = () => {
		open = true;
	};

	export const closeModal = () => {
		priority = undefined;
		note = undefined; 
		loomLink = undefined; 
		clearFile(); 
		open = false;
	};

	const submit = async () => {
		if (loading) return;
		loading = true;

		// Prep image
		let imageBase64 = undefined;
		let imageContentType = undefined;
		let imageFileExtension = undefined;

		if (selectedFile) {
			const imageArrayBuffer = await readFile();
			imageContentType = selectedFile.type;
			imageFileExtension = selectedFile.name.split('.').pop();
			imageBase64 = await arrayBufferToBase64(imageArrayBuffer);
		}

		// Payload
		const payload = {
			user: $session?.user?.email,
			pageRoute: $page.routeId,
			pageParams: $page.params,
			pageUrl: window?.location?.href,
			priority: priority,
			note: note,
			loomLink: loomLink,
			imageFileExtension: imageFileExtension,
			imageContentType: imageContentType,
			imageBase64: imageBase64,
		};

		const response = await fetch('/api/support/bug-reports/submit', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(payload),
		});

		await tryParseBody(response);
		loading = false;

		if (response.ok) {
			globalAlert.set({ message: T.out('Report submitted to engineering. Thank you!'), type: 'success' });
			closeModal();
			return;
		}

		globalAlert.set({
			message: T.out('Error occured submitting bug report, please contact engineering directly.'),
			type: 'error',
		});
	};

	function arrayBufferToBase64(buffer) {
		return new Promise((resolve, reject) => {
			const blob = new Blob([buffer], { type: 'application/octet-stream' });
			const reader = new FileReader();
			reader.onloadend = () => {
				// reader.result contains the Base64 string
				const base64String = reader.result.split(',')[1];
				resolve(base64String);
			};
			reader.onerror = reject;
			reader.readAsDataURL(blob);
		});
	}
</script>

<SlotModal bind:show={open} modalClasses="modal modal-lg" title={titleHTML}>
	<form on:submit|preventDefault={submit} class="flex flex-col gap-3 py-4">
		<div class="grid content-center">
			<SingleChoiceBars
				label={T.out('Priority')}
				bind:value={priority}
				required={true}
				choices={[
					{
						emoji: '💥',
						label: T.out(`I can't do my job`),
						value: 'urgent',
					},
					{
						emoji: '☹️',
						label: T.out(`I can work, but it's annoying`),
						value: 'alert',
					},
				]}
			/>
		</div>
		<div class="grid content-center">
			<TextBox label={T.out('Issue')} placeholder={T.out('Describe issue')} bind:value={note} required={true} />
		</div>
		<div class="grid content-center">
			<Input label={T.out('Loom')} placeholder={T.out('Enter Loom Link')} bind:value={loomLink} autocomplete="off" />
		</div>
		<div class="grid content-center">
			<FileDropzone label={T.out('Image / Video')} bind:getFile bind:clearFile bind:readFile bind:selectedFile />
		</div>
		<div class="grid grid-cols-2 content-center gap-3">
			<div>
				<Button type="submit" class="btn-md btn-blue btn-solid w-full" disabled={!priority || !note}>
					<LoadingSpinner bind:open={loading}>
						{T.out('Submit')}
					</LoadingSpinner>
				</Button>
			</div>
			<div>
				<Button type="button" class="btn-md btn-light-gray btn-text-black w-full" on:click={closeModal}>
					{T.out('Cancel')}
				</Button>
			</div>
		</div>
	</form>
</SlotModal>
