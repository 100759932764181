<script>
	import { textPromptDialog } from '$lib/stores/textPrompt';
	import { appStores } from '$lib/stores/appStores';
	import { Translator } from '$lib/js/translations/Translator';
	import { globalAlert } from '$lib/stores/globalAlert';

	const T = new Translator($appStores.language, [
		// Page translations
		{
			en: 'Please enter a valid number',
			'zh-tw': '請輸入有效號碼',
		},
	]);

	import SlotModal from '$lib/components/Modals/SlotModal.svelte';
	import Button from '$lib/components/Controls/Button.svelte';
	import TextBox from '$lib/components/Controls/TextBox.svelte';
	import Input from '$lib/components/Controls/Input.svelte';

	let open = '';
	let message = '';
	let title = '';
	let label = '';
	let placeholder = '';

	let resolve;
	let value;

	let mods = undefined;

	// Mods:
	// - type (textbox (default) || input)
	// - maxlength
	// - confirmText
	// - confirmButtonClass
	// - cancelText
	// - cancelButtonClass
	// - iconHtml
	// - topComponentSlot
	// - topComponentSlotProps
	// - label

	textPromptDialog.subscribe((val) => {
		if (val !== null) {
			open = true;
			message = val.message;
			title = val.title;
			mods = val.mods;
			label = val?.label ?? mods?.label;
			placeholder = val?.placeholder ?? mods?.placeholder;
			value = mods.value ?? undefined;
			resolve = val.resolve;
		} else {
			open = false;
			message = null;
			title = null;
			label = null;
			placeholder = null;
			mods = undefined;
			resolve = null;
		}
	});

	const handleCancel = () => {
		resolve(undefined);
		textPromptDialog.set(null);
	};

	function handleConfirm() {
		resolve(value);
		textPromptDialog.set(null);
	}
</script>

<SlotModal
	modalClasses="modal modal-md"
	zIndex="999"
	{title}
	bind:show={open}
	on:outsideClick={handleCancel}
	on:closeButtonClick={handleCancel}
>
	<div class="flex w-full flex-col gap-2 pb-3">
		{#if mods?.iconHtml}
			<div class="grid content-center py-4 px-4 text-center">
				{@html mods.iconHtml}
			</div>
		{/if}
		{#if mods?.topComponentSlot}
			<div class="grid content-center py-4 px-4 text-center">
				<svelte:component this={mods?.topComponentSlot} {...mods?.topComponentSlotProps ?? {}} />
			</div>
		{/if}
		{#if message?.length}
			<div class="grid content-center py-2 px-4 text-center">
				{@html message}
			</div>
		{/if}

		<div class={mods?.inputContainerClass ?? 'grid content-center pb-1 text-center'}>
			{#if mods?.type === 'input'}
				<Input {label} {placeholder} bind:value maxlength={mods?.maxlength} />
			{:else}
				<TextBox {label} {placeholder} rows={6} bind:value maxlength={mods?.maxlength} />
			{/if}
		</div>

		<div class="grid w-full grid-cols-2 gap-2 pb-2">
			<div>
				<Button on:click={handleConfirm} class="w-full {mods?.confirmButtonClass ?? 'btn-md btn-blue btn-solid w-full'}">
					{mods?.confirmText ?? T.out('Submit')}
				</Button>
			</div>
			<div>
				<Button on:click={handleCancel} class="w-full {mods?.cancelButtonClass ?? 'btn-md btn-light-gray btn-text-black w-full'}">
					{mods?.cancelText ?? T.out('Cancel')}
				</Button>
			</div>
		</div>
	</div>
</SlotModal>
