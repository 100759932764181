<script>
	import { confirmDialog } from '$lib/stores/confirm';
	import { appStores } from '$lib/stores/appStores';
	import { Translator } from '$lib/js/translations/Translator';

	const T = new Translator($appStores.language, [
		// Page translations
	]);

	import SlotModal from '$lib/components/Modals/SlotModal.svelte';
	import Button from '$lib/components/Controls/Button.svelte';

	let open = '';
	let message = '';
	let title = '';
	let resolve;

	let mods = undefined;

	// Mods:
	// - confirmText
	// - confirmButtonClass
	// - cancelText
	// - cancelButtonClass
	// - iconHtml
	// - topComponentSlot
	// - topComponentSlotProps

	confirmDialog.subscribe((val) => {
		if (val !== null) {
			open = true;
			message = val.message;
			title = val.title;
			mods = val.mods;
			resolve = val.resolve;
		} else {
			open = false;
			message = null;
			title = null;
			mods = undefined;
			resolve = null;
		}
	});

	function answer(value) {
		resolve(value);
		confirmDialog.set(null);
	}
</script>

<SlotModal
	modalClasses="modal modal-md"
	zIndex="999"
	{title}
	bind:show={open}
	on:outsideClick={() => answer(false)}
	on:closeButtonClick={() => answer(false)}
>
	<div class="flex w-full flex-col gap-3 py-3">
		{#if mods?.iconHtml}
			<div class="grid content-center py-4 px-4 text-center">
				{@html mods.iconHtml}
			</div>
		{/if}
		{#if mods?.topComponentSlot}
			<div class="grid content-center py-4 px-4 text-center">
				<svelte:component this={mods?.topComponentSlot} {...mods?.topComponentSlotProps ?? {}} />
			</div>
		{/if}
		<div class="grid content-center py-4 px-4 text-center">
			{@html message}
		</div>
		<div class="grid w-full grid-cols-2 gap-4">
			<div>
				<Button on:click={() => answer(true)} class="w-full {mods?.confirmButtonClass ?? 'btn-md btn-blue btn-solid w-full'}">
					{mods?.confirmText ?? T.out('Confirm')}
				</Button>
			</div>
			<div>
				<Button
					on:click={() => answer(false)}
					class="w-full {mods?.cancelButtonClass ?? 'btn-md btn-light-gray btn-text-black w-full'}"
				>
					{mods?.cancelText ?? T.out('Cancel')}
				</Button>
			</div>
		</div>
	</div>
</SlotModal>
