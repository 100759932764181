<script>
	import { imagePopout } from '$lib/stores/popoutImageStores';

	let show = false;
	let imgSrc = '';

	imagePopout.subscribe((val) => {
		if (val !== null) {
			show = true;
			imgSrc = val.url;
		} else {
			show = false;
			imgSrc = '';
		}
	});

	export const closeLargeImageModal = () => {
		imagePopout.set(null);
		show = false;
	};
</script>

<div
	class:hidden={!show}
	class="fixed top-0 right-0 left-0 z-40  grid h-full content-center items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-200/50 sm:h-full md:inset-0 {$$props.class}"
	on:click={closeLargeImageModal}
>
	<div class="relative grid h-full  w-full content-center px-4">
		<div class="grid-content-center relative rounded-lg bg-white shadow dark:bg-gray-700">
			<!-- svelte-ignore a11y-img-redundant-alt -->
			<img
				src={imgSrc}
				alt="no image found"
				class="lg:max-width-xl mx-auto w-full max-w-sm rounded-xl md:max-w-lg object-scale-down"
				style="max-width: 500px; max-height: 500px;"
			/>
		</div>
	</div>
</div>
